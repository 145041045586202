import styled from '@emotion/styled';

export const StyledLandingPage = styled.div`
  text-align: center;
`;

export const LandingPageTitle = styled.h1`
  font-size: 30px;
  padding: 15px 0;
`;
